var _product = null

/*
 * Main Product Class
 */
class ProductClass {
    constructor(){
        //singleton
        if (_product) {
            return _product;
        }
        _product = this;
    }

    deleteProductById(id, callBack){
        //Erasing all of them in 1 query
        $.ajax({
            url: Routing.generate('setProductByAction', {action: 'delete'}),
            method: 'POST',
            data: {
                id: id,
            },
            dataType: 'json',
            callBack: callBack
        });
    }

    setAvailable(id, available){
        //Erasing all of them in 1 query
        $.ajax({
            url: Routing.generate('setProductByAction', {action: 'available'}),
            method: 'POST',
            data: {
                id: id,
                available: available
            },
            dataType: 'json'
        });
    }

    getSubCategories(id, callBack){
        //Erasing all of them in 1 query
        $.ajax({
            url: Routing.generate('getProductByAction', {action: 'getCategories'}),
            method: 'POST',
            data: {
                id: id,
            },
            dataType: 'json',
            complete: callBack
        }).responseJSON;
    }

    getByFilters(filters, callBack){
        if(typeof filters == 'object')
            filters = JSON.stringify(filters);

        //Erasing all of them in 1 query
        $.ajax({
            url: Routing.generate('getProductByAction', {action: 'getProductsByFiltering'}),
            method: 'POST',
            data: {
                filters: filters
            },
            dataType: 'json',
            complete: callBack
        }).responseJSON;
    }

    getByPaginationToken(token, page, callBack){
        if(typeof filters == 'object')
            filters = JSON.stringify(filters);

        //Erasing all of them in 1 query
        $.ajax({
            url: Routing.generate('getProductByAction', {action: 'getProductsByTokenPagination'}),
            method: 'POST',
            data: {
                token: token,
                page: page
            },
            dataType: 'json',
            complete: callBack
        }).responseJSON;
    }
}

export default ProductClass;
