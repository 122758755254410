import './base.css';

require('jquery');
import ProductClass from '../Product/Product.js';

var modules = [];

/*
 *  Category page
 */
class ProductCategory{

    constructor(){
        this.categoryId = false;
        this.bindEvents();
        this.ready = false
        this.pathname = false;
        this.basepath = location.protocol + '//' + location.host + location.pathname;
        this.loadPage = 1;

        return this;
    }

    init(){

        // $('.clickOne').first().click();
        // $('.clickTwo').first().click();
        // $('.clickThree').first().click();
        // $('.clickFour').first().click();
        
        //Add the page to pathname if defined
        var page = this.getUrlVars()['page'];

        if(typeof page != 'undefined'){
            this.loadPage = page;
            this.basepath = this.buildUrl(this.pathname, 'page', page);
        }

        //If any params in the URL we do a few clicks to restore original filter
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        var changes = false;

        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            if(typeof hash[1] != 'undefined'){
                $('#'+hash[0]).trigger('click');
                changes=true;
            }
        }

        this.ready = true;

        //if(changes)
        //    this.refreshProductsListing(null);
    }

    bindEvents(){
        $(document.body).on('click', '.filtering', this.refreshProductsListing);
        $(document).on('click', '.category-products-filter-by-btn-close', this.removeFilter);

        // $(document).on('click', '.category-product-filter-hierarchy-title', this.categorySelected);
        // $(document).on('click', '.category-product-filter-hierarchy-back-btn', this.categoryBack);

        //$(document).on('click', '.pagination-ajax', this.paginationFilter);
    }

    paginationFilter(event){
        event.preventDefault;
        var page = $(this).data('page');
        var token = $(this).data('token');

        var _this = modules['category'];
        console.log('Loading page '+page);
        modules['product'].getByPaginationToken(token, page, _this.refreshProducts);

        //Update the page
        //_this.pathname = _this.buildUrl(_this.pathname, 'page', page);
        //_this.changeUrl();
    }

    categoryBack(event){
        //clearTextFilter();
        if ($(this).parent().hasClass("category-product-filter-hierarchy")){
            $(this).parent().siblings().show();
            $(this).siblings().hide();
            $(this).hide();
            $(this).siblings('.category-product-filter-hierarchy-title').show();
            $(this).siblings('.category-product-filter-hierarchy-title').removeClass('active');
            $('.category-header-breadcrumb-wrapper').hide();
        }else{
            $(this).siblings().hide();
            $(this).siblings('.underline-effect-wrapper').show();
            $(this).hide();
            $(this).siblings('.underline-effect-wrapper').children().find('.category-product-filter-hierarchy-title').removeClass('active');
            var parentClass = '.' + $(this).parent().attr('class');
            $(this).parent().siblings(parentClass).show();
            if($(this).parent().attr('class') === 'category-product-filter-hierarchy-l2'){
                $('#category-header-breadcrumb-l2').hide();
                $('#category-header-breadcrumb-l3').hide();
            }else if($(this).parent().attr('class') === 'category-product-filter-hierarchy-l3'){
                $('#category-header-breadcrumb-l3').hide();
            }
            $(this).parent().find(".category-product-filter-hierarchy-title").removeClass('active');
        }

    }

    categorySelected(event){
        var _this = modules['category'];

        if ($(this).parent().hasClass("category-product-filter-hierarchy")) {
            //Remove all active on the current branch
            $(this).parent().parent().find('.active').each(function(){
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            $(this).parent().children('.category-product-filter-hierarchy-l2').show();
            $(this).parent().children('.category-product-filter-hierarchy-back-btn').show();
            $(this).parent().children('.category-product-filter-hierarchy-back-btn').find('i').css('color', 'white');
            $(this).parent().siblings().hide();
            $('#category-header-breadcrumb-l1 a').text(_this.transformText($(this).text()));
            $('#category-header-breadcrumb-l1').show();
            $('#category-header-breadcrumb-l2').hide();
            $('#category-header-breadcrumb-l3').hide();
        } else if ($(this).parent().parent().hasClass("category-product-filter-hierarchy-l2")){
            //Remove all active on the current branch
            $(this).parent().parent().find('.active').each(function(){
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            $(this).parent().parent().children('.category-product-filter-hierarchy-l3').show();
            $(this).parent().parent().children('.category-product-filter-hierarchy-back-btn').show();
            $(this).parent().parent().siblings(".category-product-filter-hierarchy-l2").hide();
            $('#category-header-breadcrumb-l2 a').text(_this.transformText($(this).text()));
            $('#category-header-breadcrumb-l2').show();
            $('#category-header-breadcrumb-l3').hide();

        } else if ($(this).parent().parent().hasClass("category-product-filter-hierarchy-l3")){

            //Remove all active on the current branch
            $(this).parent().parent().find('.active').each(function(){
                $(this).removeClass('active');
            });
            //clearTextFilter();
            $(this).addClass('active');
            $(this).parent().parent().children('.category-product-filter-hierarchy-l4').show();
            $(this).parent().parent().siblings('.category-product-filter-hierarchy-l3').children('.category-product-filter-hierarchy-l4').hide();
            $(this).parent().parent().siblings('.category-product-filter-hierarchy-l3').find('.category-product-filter-hierarchy-title').removeClass('active');
            $(this).parent().parent().siblings('.category-product-filter-hierarchy-l3').find('.category-product-filter-hierarchy-back-btn').hide();
            $('#category-header-breadcrumb-l3 a').text(_this.transformText($(this).text()));
            $('#category-header-breadcrumb-l3').show();

        } else if ($(this).parent().parent().hasClass("category-product-filter-hierarchy-l4")){
            $(this).siblings().children().find('.category-product-filter-hierarchy-title').removeClass('active');
            $(this).parent().parent().parent().siblings().children().find('.category-product-filter-hierarchy-title').removeClass('active');
            $(this).parent().parent().parent().siblings().children('.category-product-filter-hierarchy-l4').hide();
        }

        //Just to be sure while fixing a bug found, remove all active from previous branches
        $('.category-product-filter').find(':hidden').each(function(){
            $(this).removeClass('active');
        });

        //Ok let's build the menu
        //We reset and change the
        if ($(this).hasClass('selected')) {
            var textFeildId = $(this).attr("id");
            $('#checked-filters').find("button[value='" + textFeildId + "']").parent().remove();
            $('.category-product-filter-hierarchy-title').removeClass('selected');

        } else {
            var checkedFilters = $('.category-products').find('#checked-filters');
            $('.category-filter-by').remove();

            var parentsTextArray = [];
            var last = '';
            $('.category-product-filter').find('.active').each(function(){
                last = _this.transformText($(this).text()).replace(/\n/g, '');
                parentsTextArray.push(last);
            });
            //@TODO fix level 4, it'S a pain from UK team
            var testLevel4 = _this.transformText($(this).text().replace(/\n/g, ''));
            if(testLevel4 != last)
                parentsTextArray.push(testLevel4);

            var textForBtn = parentsTextArray.join('&nbsp; >> &nbsp;');
            var newDiv = $(' <div class="category-products-filter-by category-filter-by">' + '<span>'+textForBtn+'</span>' + '<button class="category-products-filter-by-btn-close" value="' + $(this).attr("id") + '"></button></div>');
            //clearTextFilter();

            checkedFilters.append(newDiv);
            $('.category-product-filter-hierarchy-title').removeClass('selected');
            $(this).addClass('selected');
        }

        //If not ready yet we do nothing
        if(_this.ready ){
            var filters = _this.buildFilters();
            modules['product'].getByFilters(filters, _this.refreshProducts);
        }
    }

    removeFilter(){
        var _this = modules['category'];
        var filter = '#'+$(this).val();

        console.log('Removing filter on '+filter);
        if($(filter).is('input')){
            $(filter)[0].checked = false;
        }else{
        }

        if ($(filter).hasClass('active')) {
            $(filter).removeClass('active');
        }

        if ($(filter).hasClass('selected')) {
            $(filter).removeClass('selected');
        }

        $(this).parent().remove();
        var filters = _this.buildFilters();
        modules['product'].getByFilters(filters, _this.refreshProducts);
    }

    refreshProductsListing(event){
        var _this = modules['category'];


        //They clicked on the link of categories
        if($(this).is('a')){
            //The Category are not meant to be done right away
            return true;

        }else{
            //It's a checkbox
            if($(this).hasClass('selected'))
                $(this).removeClass('selected');
            else
                $(this).addClass('selected');
        }

        //If not ready yet we do nothing
        if(_this.ready ){
            var filters = _this.buildFilters();
            modules['product'].getByFilters(filters, _this.refreshProducts);
        }
    }

    filterRemoved(){
        var _this = modules['category'];

        var filters = _this.buildFilters();
        modules['product'].getByFilters(filters, _this.refreshProducts);
    }

    buildFilters(){
        var _this = modules['category'];
        var url = _this.basepath;

        //Ok let's gather all the filters
        var filters = [];
        /*
        $('.selected').each(function(){
            filters.push({
                'type': $(this).data('type'),
                'id': $(this).data('id')
            });
        });
*/
        $('.active').each(function(){;
            if($(this).data('type')){
                filters.push({
                    'type': $(this).data('type'),
                    'id': $(this).data('id')
                });
            }
        });

        $('input[type=checkbox]').each(function(){
            console.log(this);
            if(this.checked && $(this).data('type').length > 0){
                console.log('Checked');
                filters.push({
                    'type': $(this).data('type'),
                    'id': $(this).data('id')
                });
            }
        });

        //This is when a call from the URL to load the good one
        if(_this.loadPage > 1 ){
            _this.loadPage = 1;
            filters.push({
                'type': 'page',
                'id': _this.loadPage
            });
            //url = _this.buildUrl(url, 'page', '1');
        }

        this.pathname = url;

        return filters;
    }

    refreshProducts(data){
        //var _this = modules['category'];
        //modules['category'].changeUrl();

        if(typeof data.responseJSON.token != 'undefined'){
            window.location = '/produits/recherche/'+data.responseJSON.token;
        }else{
            window.location = '/categorie';
        }

        //$('#listingProducts').html(data.responseJSON.data.html);
        //$('#amountProducts').html(data.responseJSON.data.amount);


        //modules['category'].refreshScroll();
    }

    refreshScroll(){
        $('.masonry-layout__panel').each( function(i){
          var bottom_of_object = $(this).offset().top + $(this).outerHeight()-300;
          var bottom_of_window = $(window).scrollTop() + $(window).height();
          if( bottom_of_window > bottom_of_object ){
            $(this).animate({'opacity':'1'},500);
          }
        });
    }

    transformText(text) {
        var s = text.toLowerCase();
        s = s.charAt(0).toUpperCase() + s.substr(1);
        return s;
    }

    /*
     * Changing dynamicly the url of the browser
     */
    changeUrl(){
        var url = this.pathname;
        var params = url.split('?');

        //Let's first by building the pathname from the categories
        var category = $('.category-products-filters-wrap').find('.selected');
        if(typeof category == 'undefined')
            category = $('.category-products-filters-wrap').find('.active');

        if(typeof category != 'undefined' && typeof $(category).data('url') != 'undefined'){
            console.log('Url category defined');

            var pathname = '/categorie'+$(category).data('url');
            var url= location.protocol + '//' + location.host + pathname;

            if(typeof params[1] != 'undefined'){
                url = url +'?'+params[1];
            }
        }else{
            console.log('Url no category defined');
            var pathname = '/categorie';
            var url= location.protocol + '//' + location.host + pathname;

            if(typeof params[1] != 'undefined'){
                url = url +'?'+params[1];
            }
        }
        console.log('Changing URL to: '+url);
        history.pushState(null, null, url);
    }

    /**
    * Add a URL parameter (or modify if already exists)
    * @param {string} url
    * @param {string} param the key to set
    * @param {string} value
    */
    buildUrl(url, param, value) {
       param = encodeURIComponent(param);
       var r = "([&?]|&amp;)" + param + "\\b(?:=(?:[^&#]*))*";
       var a = document.createElement('a');
       var regex = new RegExp(r);
       var str = param + (value ? "=" + encodeURIComponent(value) : "");
       a.href = url;
       var q = a.search.replace(regex, "$1"+str);
       if (q === a.search) {
          a.search += (a.search ? "&" : "") + str;
       } else {
          a.search = q;
       }

       return a.href;
    }

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function(m,key,value) {
          vars[key] = value;
        });
        return vars;
    }
}

/*
 * Once window loaded init the class
 */
$( document ).ready(function() {
    console.log('Category Product loaded');
    modules['product'] = new ProductClass();
    modules['category'] = new ProductCategory();
    modules['category'].init();
});
